<template>
  <div class="create-accrual">
    <p class="create-accrual__title">
      Добавление минут
    </p>

    <div class="create-accrual__infoblock infoblock">
      <p class="infoblock__title">
        Остаток минут
      </p>

      <p class="infoblock__text">
        {{ currentMinutes }}
      </p>
    </div>

    <div class="create-accrual__infoblock infoblock">
      <div class="infoblock__content">
        <p class="infoblock__title">
          Баланс
        </p>

        <p class="infoblock__text">
          {{ balance }}
        </p>
      </div>
    </div>

    <template v-if="isAdmin">
      <default-select
        v-model="ratesId"
        class="create-accrual__rate"
        placeholder="Ставка"
        :items="ratesTypes"
        @input="(choosenItem) => ratesId = choosenItem.id"
      />

      <div class="create-accrual__add-minutes">
        <label class="create-accrual__label">
          Минуты
        </label>

        <input-number
          v-model="addedMinutes"
          style="border-color: #e4e4e4"
          :maxlength="5"
          @input="(value) => addedMinutes = value"
        />
      </div>

      <Button
        type="tertiary"
        @click="createAccrual"
      >
        Добавить
      </Button>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import accruals from '@/api/accruals'
import DefaultSelect from '@/basic/DefaultSelect'
import InputNumber from '@/basic/InputNumber.vue'
import Button from '@/basic/Button'

export default defineComponent({
  name: 'CreateAccrual',
  components: {
    DefaultSelect,
    InputNumber,
    Button,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    ratesId: 1,
    balance: 0,
    currentMinutes: 0,
    addedMinutes: '',
    ratesTypes: [
      {
        id: 1,
        name: 'Старая ставка',
      },
      {
        id: 2,
        name: 'Обычное занятие',
      },
      {
        id: 3,
        name: 'Подготовка к ОГЭ/ЕГЭ',
      },
      {
        id: 4,
        name: 'Олимпиады',
      },
      {
        id: 5,
        name: 'Логопедия',
      },
    ],
  }),
  async mounted () {
    this.getBalance()
  },
  methods: {
    openNotice(type, text, timer) {
      this.$store.commit(
        'setNoticeData',
        `<p style="margin: 2rem 3rem; font-size: 14px">${text}</p>`
      )

      this.$store.commit('setNotice', type)

      setTimeout(() => {
        this.$store.commit('setNoticeData', null)
        this.$store.commit('setNotice', null)
      }, timer)
    },
    async createAccrual () {
      try {
        const { data: response, } = await accruals.createAccrual(
          this.userId,
          this.ratesId,
          this.addedMinutes
        )

        if (response.result.succsess) {
          this.openNotice(
            'InfoNotice',
            `Успешно сделали начисление преподователю: ${this.userId}`,
            5000
          )
          this.getBalance()
        } else {
          this.openNotice(
            'ErrorNotice',
            response.result.message,
            5000
          )
        }
      } catch (error) {
        this.openNotice(
          'ErrorNotice',
          'Произошла необработанная ошибка',
          5000
        )
      } finally {
        this.addedMinutes = ''
      }
    },
    async getBalance () {
      try {
        const { data: response, } = await accruals.getSpecialistBalance(this.userId)

        if (response?.success) {
          this.balance = response?.result?.balance
          this.currentMinutes = response?.result?.minutes
        }
      } catch (error) {
        console.warn(error)
      }
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.create-accrual {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 450px;
  &__title {
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__label {
    font-size: 12px;
    color: $gray;
  }
}

.infoblock {
  position: relative;
  &__title {
    position: absolute;
    top: -8px;
    left: 5px;
    font-size: 12px;
    background: $light-100;
    color: $gray;
  }
  &__text {
    padding: 6px;
    border: 1px solid $medium-gray;
  }
}
</style>
