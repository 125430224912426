import Vue from 'vue'

export default {
  getAccruals (limit, page) {
    return Vue.axios.get(`/dashboard/accruals?limit=${limit}&page=${page}`)
  },

  getSpecialistBalance (userId) {
    return Vue.axios.get(`/dashboard/specialists/${userId}/balance?userID=${userId}`)
  },

  handleAccrual (accrualId, accrualAprroved) {
    return Vue.axios.post(
      '/dashboard/accruals/handle/',
      {
        requestID: accrualId,
        isAprooved: accrualAprroved,
      }
    )
  },

  createAccrual (currentUserId, currentRateId, addedMinutes) {
    return Vue.axios.post(
      '/dashboard/accruals/create-accrual/',
      {
        userID: currentUserId,
        rateID: currentRateId,
        minutes: addedMinutes,
      }
    )
  }
}
